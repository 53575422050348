import logo from './logo.svg';
import './App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { FormCard } from './FormCard';
import Config from './config.json';

function App() {
    const [openbankOption, setOpenbank] = useState(false);
    const navigate = useNavigate();

    function renderContent() {
        if (openbankOption) {
            return <FormCard />
        }
        else {
            return (

                <div class="container-btns">
                    <div class="boton-pre boton-clientes">
                        <a class="clientes" href={Config.urls.no_client}>
                            <div>COMPRAR <br /> ENTRADAS</div>
                        </a>
                        <a className='click_here'  href="https://inicioentradas.rockcircus.show/espectaculo/rock-circus/ROCKCIRCUS">
                            
                        </a>
                    </div>
                    <div class="boton-pre boton-clientes"  onClick={() => navigate("/ventaopenbank")}>
                        <button> CLIENTES <br /> OPENBANK</button>
                        <a className='click_here'>
                            
                        </a>
                    </div>
                </div>

            );
        }

    }
    return (
        <React.Fragment>
            <div class="header"><img src="/logotipos/diver-logo-desktop.png" alt="" /></div>
            <div class="blackfriday"><img src="https://firebasestorage.googleapis.com/v0/b/jmdb-production.appspot.com/o/establecimientos%2Fextras%2Fextras%2FFOMNTWOW0L5%2FfvFOMNTWOW0L5%40_UrlIAE__fichero-iae.png?alt=media" alt="" /></div>
            {renderContent()}
        </React.Fragment>

    )
}

export default App;
